.icon {
  width: 24px;
  height: 24px;
}

.white {
  fill: white;
}

.txtItem {
  margin-left: 5px;
}
