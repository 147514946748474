.explorer {
  width: 160px;
  height: 100%;
  flex-shrink: 0;
  overflow-y: auto;
}

.item {
  flex-direction: column;
}

.label {
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.img {
  width: 100%;
  height: 100%;
}
