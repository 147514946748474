.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  height: calc(100% - 48px);
}

.rightSide {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

