.toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding: 10px;
}

.zoom {
  position: absolute;
  display: flex;
  align-items: center;
}
