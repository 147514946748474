.toolbar {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.icon {
  width: 24px;
  height: 24px;
}

.white {
  fill: white;
}

.row {
  display: flex;
  align-items: center;
}
